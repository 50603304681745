import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import contactForm from "./contactForm"

const Footer = () => (
  <section className="footer">
    <div className="wrap">
      <div className="column">
        <img src={`/static/wig-logo-fifi-white.png`} alt="" />
        <p>
          ul. Ogarna 51/52 lok.1
          <br />
          80-826 Gdańsk
        </p>
        <p>
          tel: <a href="tel:+48607202052">+48 607 202 052</a> <br />
          tel: <a href="tel:+48603599714">+48 603 599 714</a> <br />
          e-mail: <a href="mailto:biuro@fifitravel.pl">
            biuro@fifitravel.pl
          </a>{" "}
          <br />
          www: <a href="https://fifitravel.pl">https://fifitravel.pl</a>
        </p>
      </div>
      <div className="column">
        <h5>Oferta:</h5>
        <ul>
          <li>
            <Link to="/aktywny-weekend-w-gdansku">
              Aktywny weekend w Gdańsku
            </Link>
          </li>
          <li>
            <Link to="/historyczny-weekend-w-gdansku">
              Historyczny weekend w Gdańsku
            </Link>
          </li>
          <li>
            <Link to="/kulinarny-weekend-w-gdansku">
              Kulinarny weekend w Gdańsku
            </Link>
          </li>
          <li>
            <Link to="/nadmorski-weekend-w-gdansku">
              Nadmorski weekend w Gdańsku
            </Link>
          </li>
          <li>
            <Link to="/rodzinny-weekend-w-gdansku">
              Rodzinny weekend w Gdańsku
            </Link>
          </li>
        </ul>
      </div>
      <div className="column">
        <h5>&nbsp;</h5>
        <ul>
          <li>
            <Link to="/romantyczny-weekend-w-gdansku">
              Romantyczny weekend w Gdańsku
            </Link>
          </li>
          <li>
            <Link to="/kajakowy-weekend-w-gdansku">
              Kajakowy weekend w Gdańsku
            </Link>
          </li>
          <li>
            <Link to="/weekend-na-koncu-polski">Weekend na końcu Polski</Link>
          </li>
          <li>
            <Link to="/rowerowy-weekend-w-gdansku">
              Rowerowy weekend w Gdańsku
            </Link>
          </li>
          <li>
            <Link to="/weekend-w-trojmiescie">Weekend w Trójmieście</Link>
          </li>
        </ul>
      </div>
      <div className="column">
        <h5>Pliki cookies</h5>
        <p>
          Ninejsza strona internetowa wykorzysuje pliki cookies (ciasteczka),
          zgodnie z obowiązującymi zasadami Regulaminu i Polityki Prywatności, a
          także obowiązującymi przepisami i regulacjami o ochronie danych
          osobowych, w celach marketingowych (również analizowanie i
          profilowanie w celach marketingowych) i prowadzenia reklamy
          podążającej.
        </p>
      </div>
    </div>
  </section>
)

export default Footer
