import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
    <div className="wrap">
      <div className="title-area">
        <Link to="/">
          <h1>
            <img src={`/static/fifitravel-logo-white-section.png`} alt="" />
          </h1>
        </Link>
      </div>
      <nav>
        <Link to="/#o-nas">O nas</Link>
        <Link to="/#propozycje">Propozycje</Link>
        <Link to="/kontakt">Kontakt</Link>
      </nav>
      <div className="header-contact">
        <div className="email">
          <a href="mailto:biuro@fifitravel.pl">biuro@fifitravel.pl</a>
        </div>
        <div className="phone">
          <a href="tel:+48607202052">+48 607 202 052</a>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
