import React, { Component } from "react"
import axios from "axios"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"

const API_PATH = "/api-mail.php"
const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Zawartość pola jest zbyt krótka")
    .max(50, "Zawartość pola jest zbyt długa")
    .required("Pole wymagane"),
  email: Yup.string()
    .min(2, "Zawartość pola jest zbyt krótka")
    .max(50, "Zawartość pola jest zbyt długa")
    .email("Nieprawidłowy format adresu e-mail")
    .required("Pole wymagane"),
  phone: Yup.string()
    .min(5, "Zawartość pola jest zbyt krótka")
    .max(15, "Zawartość pola jest zbyt długa")
    .required("Pole wymagane"),
  message: Yup.string().max(
    1000,
    "Zawartość pola jest zbyt długa (maksymalnie 1000 znaków"
  ),
  privacy: Yup.bool().oneOf([true], "Wymagana zgoda"),
})

export default class contactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      phone: "",
      mailSent: false,
      error: null,
    }
  }
  handleFormSubmit = e => {
    // e.preventDefault();
    axios({
      method: "POST",
      url: `${API_PATH}`,
      headers: { "content-type": "application/json" },
      data: e,
    })
      .then(result => {
        this.setState({
          mailSent: result.data.sent,
          error: result.data.message,
        })
        // window.gtag("event", "conversion", {
        //   send_to: "AW-641293041/a9mZCIbcm88BEPG15bEC",
        //   ...data,
        // })
      })
      .catch(error => this.setState({ error: error.message }))
  }

  render() {
    if (this.state.mailSent === false) {
      return (
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            message: "",
            privacy: false,
          }}
          validationSchema={SignupSchema}
          onSubmit={values => {
            this.handleFormSubmit(values)
          }}
        >
          {({ isSubmitting }) => (
            <Form id="form">
              <h4>Pytania, rezerwacje, wątpliwości:</h4>
              <Field type="text" name="name" placeholder="Imię i nazwisko" />
              <ErrorMessage name="name" component="div" />
              <Field type="email" name="email" placeholder="Adres e-mail" />
              <ErrorMessage name="email" component="div" />
              <Field type="phone" name="phone" placeholder="Numer telefonu" />
              <ErrorMessage name="phone" component="div" />
              <Field
                type="text"
                component="textarea"
                name="message"
                rows="4"
                placeholder="Treść wiadomości"
              />
              <ErrorMessage name="message" component="div" />
              <button
                className="submit button"
                type="submit"
                disabled={isSubmitting}
              >
                Wyślij
              </button>
              <Field
                className="checkbox"
                type="checkbox"
                id="privacy"
                name="privacy"
              />
              <label className="privacy" htmlFor="privacy">
                Wyrażam zgodę na przetwarzanie moich danych osobowych przez
                Biuro Turystyczne Fifi Travel Maria Fifielska z siedzibą w
                Gdańśku przy ul. Ogarnej 51/52/1 w celu nawiązania kontaktu
                zwrotnego. Akceptuję{" "}
                <a
                  href="https://fifitravel.pl/polityka-prywatnosci"
                  target="blank"
                >
                  politykę prywatności
                </a>
              </label>
              <ErrorMessage name="privacy" component="div" />
            </Form>
          )}
        </Formik>
      )
    } else {
      return (
        <form className="message-sent">
          <h3>Dziękujemy!</h3>
          <p>Wiadomość została wysłana.</p>
        </form>
      )
    }
  }
}
